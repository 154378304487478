import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { themeStyles } from '../utils/theme'

const ThankYou = ({ location }) => (
  <Layout location={location}>
    <Seo title="Contact Us" />
    <div css={themeStyles.textPadding}>
      <h2>Thank You</h2>
      <div>
        Thank you for your message. We will get back to you shortly with a response.
      </div>
    </div>
  </Layout>
)

export default ThankYou